const MiddleLid = ({
  className,
  showText,
  strokeWidth,
  filter,
  fill,
  stroke,
  transform,
  viewBox,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      stroke={stroke}
      preserveAspectRatio="xMidYMid meet"
      viewBox={viewBox}
      fill={fill}
      stroke-width={strokeWidth}
      filter={filter}
      class={className}
    >
      {showText && (
        <text
          font-weight="bold"
          font-size="27"
          x="440"
          y="650"
          transform="scale(2.8)"
          paint-order="stroke"
          stroke="#000000"
          stroke-width="2px"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          fill="white"
        >
          Middle Lid
        </text>
      )}
      <path
        id="Selection"
        transform={transform}
        d="M 479.00,536.00
           C 479.00,536.00 512.00,526.51 512.00,526.51
             512.00,526.51 534.00,522.99 534.00,522.99
             534.00,522.99 556.00,516.73 556.00,516.73
             556.00,516.73 569.00,514.00 569.00,514.00
             569.00,514.00 585.00,510.95 585.00,510.95
             587.38,510.54 590.55,509.73 592.72,510.95
             595.84,512.90 596.09,517.77 595.91,521.00
             595.91,521.00 593.17,541.00 593.17,541.00
             593.17,541.00 592.01,562.00 592.01,562.00
             592.01,562.00 593.83,575.00 593.83,575.00
             593.83,575.00 594.44,585.00 594.44,585.00
             594.44,585.00 599.86,606.00 599.86,606.00
             599.86,606.00 606.14,621.00 606.14,621.00
             606.14,621.00 618.42,662.00 618.42,662.00
             621.92,674.24 624.48,688.21 634.00,697.28
             636.44,699.61 641.33,704.60 638.97,708.37
             637.59,710.56 630.54,713.33 628.00,714.42
             628.00,714.42 599.00,726.00 599.00,726.00
             554.86,740.73 520.52,746.54 474.00,746.00
             474.00,746.00 437.00,741.59 437.00,741.59
             432.28,740.79 426.28,740.92 425.53,734.98
             425.53,734.98 425.53,727.00 425.53,727.00
             425.60,723.04 428.17,713.35 429.27,709.00
             429.27,709.00 436.42,684.00 436.42,684.00
             436.42,684.00 444.81,664.00 444.81,664.00
             444.81,664.00 453.87,628.00 453.87,628.00
             453.87,628.00 459.15,590.00 459.15,590.00
             459.15,590.00 471.60,553.00 471.60,553.00
             471.60,553.00 479.00,536.00 479.00,536.00 Z"
      />
    </svg>
  );
};

export default MiddleLid;
