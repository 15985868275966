const AboveCrease = ({
  className,
  showText,
  strokeWidth,
  filter,
  fill,
  stroke,
  transform,
  viewBox,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      stroke={stroke}
      preserveAspectRatio="xMidYMid meet"
      viewBox={viewBox}
      fill={fill}
      stroke-width={strokeWidth}
      filter={filter}
      class={className}
    >
      {showText && (
        <text
          font-weight="700"
          font-size="27"
          x="700"
          y="300"
          transform="scale(2.8)"
          paint-order="stroke"
          stroke="#000000"
          stroke-width="2px"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          fill="white"
        >
          Above Crease
        </text>
      )}

      <path
        transform={transform}
        id="Selection"
        d="M 222.00,413.00
           C 232.71,412.99 229.36,411.74 238.00,408.40
             238.00,408.40 260.00,401.74 260.00,401.74
             260.00,401.74 269.00,399.48 269.00,399.48
             269.00,399.48 278.00,395.82 278.00,395.82
             278.00,395.82 286.00,393.54 286.00,393.54
             286.00,393.54 299.00,387.69 299.00,387.69
             299.00,387.69 311.00,383.55 311.00,383.55
             311.00,383.55 319.00,379.86 319.00,379.86
             319.00,379.86 332.00,375.41 332.00,375.41
             332.00,375.41 343.00,370.87 343.00,370.87
             343.00,370.87 358.00,365.53 358.00,365.53
             358.00,365.53 375.00,357.91 375.00,357.91
             375.00,357.91 388.00,354.05 388.00,354.05
             388.00,354.05 403.00,347.45 403.00,347.45
             403.00,347.45 421.00,340.80 421.00,340.80
             421.00,340.80 429.00,337.06 429.00,337.06
             429.00,337.06 445.00,331.95 445.00,331.95
             445.00,331.95 454.00,328.51 454.00,328.51
             454.00,328.51 466.00,325.33 466.00,325.33
             466.00,325.33 506.00,311.57 506.00,311.57
             506.00,311.57 524.00,308.10 524.00,308.10
             524.00,308.10 542.00,300.43 542.00,300.43
             542.00,300.43 569.00,294.76 569.00,294.76
             569.00,294.76 589.00,289.00 589.00,289.00
             589.00,289.00 608.00,285.96 608.00,285.96
             608.00,285.96 623.00,281.50 623.00,281.50
             623.00,281.50 635.00,279.49 635.00,279.49
             635.00,279.49 651.00,274.89 651.00,274.89
             651.00,274.89 669.00,271.35 669.00,271.35
             669.00,271.35 697.00,265.13 697.00,265.13
             697.00,265.13 706.00,265.13 706.00,265.13
             706.00,265.13 717.00,263.84 717.00,263.84
             717.00,263.84 735.00,261.00 735.00,261.00
             735.00,261.00 756.00,261.00 756.00,261.00
             756.00,261.00 772.00,260.00 772.00,260.00
             772.00,260.00 819.00,260.00 819.00,260.00
             819.00,260.00 842.00,262.83 842.00,262.83
             842.00,262.83 860.00,264.29 860.00,264.29
             860.00,264.29 876.00,266.85 876.00,266.85
             876.00,266.85 894.00,269.08 894.00,269.08
             903.55,270.76 910.74,273.88 921.00,274.00
             934.24,274.15 939.79,276.96 944.00,276.65
             948.74,276.78 955.40,273.86 959.57,276.65
             964.01,279.18 965.89,284.49 968.00,289.00
             974.05,301.93 977.17,308.36 977.00,323.00
             976.89,332.18 974.66,341.00 973.27,350.00
             969.94,371.59 963.62,398.43 954.03,418.00
             954.03,418.00 946.55,431.00 946.55,431.00
             935.95,450.01 934.02,452.10 920.66,469.00
             916.64,474.09 913.53,481.04 907.00,483.00
             907.00,483.00 903.46,471.00 903.46,471.00
             902.74,466.87 903.60,464.10 901.15,460.00
             898.54,455.65 891.89,450.60 888.00,446.98
             875.70,435.51 862.45,425.20 849.00,415.13
             824.79,397.00 783.27,371.42 755.00,361.05
             735.79,354.01 706.37,347.11 686.00,345.17
             686.00,345.17 652.00,344.00 652.00,344.00
             652.00,344.00 637.00,343.00 637.00,343.00
             637.00,343.00 600.00,343.00 600.00,343.00
             600.00,343.00 583.00,344.00 583.00,344.00
             583.00,344.00 550.28,346.59 550.28,346.59
             543.60,347.47 541.36,349.00 534.00,348.80
             528.79,348.67 524.09,350.43 519.00,351.39
             519.00,351.39 487.00,358.11 487.00,358.11
             487.00,358.11 448.00,367.69 448.00,367.69
             429.11,374.58 409.45,388.20 393.00,399.56
             393.00,399.56 385.29,404.63 385.29,404.63
             381.77,407.53 374.64,416.99 371.63,421.00
             371.63,421.00 347.67,456.00 347.67,456.00
             339.48,468.14 335.06,476.95 328.74,490.00
             328.74,490.00 322.69,503.00 322.69,503.00
             322.69,503.00 318.27,519.00 318.27,519.00
             316.27,526.90 314.01,534.82 314.04,543.00
             314.04,543.00 314.04,556.00 314.04,556.00
             314.04,556.00 314.82,563.00 314.82,563.00
             314.82,563.00 314.00,573.00 314.00,573.00
             313.86,584.91 312.36,588.33 311.17,599.00
             310.09,608.65 309.72,618.35 304.78,627.00
             303.33,629.54 294.43,639.33 291.91,642.00
             291.91,642.00 284.10,649.28 284.10,649.28
             280.55,652.73 276.48,660.07 270.31,655.98
             267.96,654.42 266.93,645.27 266.00,642.00
             266.00,642.00 258.42,613.00 258.42,613.00
             258.42,613.00 256.31,601.00 256.31,601.00
             256.31,601.00 253.51,595.00 253.51,595.00
             253.51,595.00 248.06,581.00 248.06,581.00
             248.06,581.00 235.91,543.00 235.91,543.00
             235.91,543.00 232.68,536.00 232.68,536.00
             228.62,525.77 225.59,513.85 223.61,503.00
             221.04,488.82 222.00,473.44 222.00,459.00
             222.00,459.00 222.00,431.00 222.00,431.00
             222.00,425.03 221.15,420.41 224.00,415.00
             224.00,415.00 223.00,415.00 223.00,415.00
             223.00,415.00 222.00,413.00 222.00,413.00 Z"
      />
    </svg>
  );
};

export default AboveCrease;
