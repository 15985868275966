const DeepCrease = ({
  className,
  showText,
  strokeWidth,
  filter,
  fill,
  stroke,
  transform,
  viewBox,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      stroke={stroke}
      preserveAspectRatio="xMidYMid meet"
      viewBox={viewBox}
      fill={fill}
      stroke-width={strokeWidth}
      filter={filter}
      class={className}
    >
      {showText && (
        <text
          font-weight="bold"
          font-size="27"
          x="530"
          y="470"
          transform="scale(2.8)"
          paint-order="stroke"
          stroke="#000000"
          stroke-width="2px"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          fill="white"
        >
          Deep Crease
        </text>
      )}
      <path
        id="Selection"
        transform={transform}
        d="M 358.00,568.00
           C 358.69,564.98 359.83,560.71 361.27,558.00
             363.67,553.52 377.44,539.20 382.00,536.33
             382.00,536.33 399.00,528.37 399.00,528.37
             399.00,528.37 418.00,516.32 418.00,516.32
             418.00,516.32 440.00,507.88 440.00,507.88
             440.00,507.88 461.00,496.12 461.00,496.12
             461.00,496.12 478.00,488.82 478.00,488.82
             478.00,488.82 496.00,479.42 496.00,479.42
             496.00,479.42 511.00,472.37 511.00,472.37
             511.00,472.37 524.00,465.59 524.00,465.59
             524.00,465.59 557.00,456.28 557.00,456.28
             557.00,456.28 606.00,442.46 606.00,442.46
             616.93,440.41 624.00,440.02 635.00,440.00
             635.00,440.00 650.00,439.00 650.00,439.00
             650.00,439.00 678.00,439.00 678.00,439.00
             688.31,439.02 696.83,443.14 706.00,447.58
             715.46,452.16 725.55,456.46 734.00,462.76
             734.00,462.76 743.00,470.25 743.00,470.25
             743.00,470.25 769.00,491.10 769.00,491.10
             769.00,491.10 784.48,502.52 784.48,502.52
             784.48,502.52 798.34,522.00 798.34,522.00
             798.34,522.00 806.54,534.00 806.54,534.00
             814.02,546.84 821.15,568.37 824.42,583.00
             826.10,590.47 827.93,597.28 828.00,605.00
             823.74,604.00 815.64,601.01 812.37,598.20
             809.77,595.96 808.58,592.93 803.00,588.52
             803.00,588.52 791.00,580.28 791.00,580.28
             791.00,580.28 774.00,567.31 774.00,567.31
             774.00,567.31 748.00,548.69 748.00,548.69
             731.23,535.07 707.82,514.60 687.00,508.44
             668.01,502.82 665.19,505.97 648.00,506.00
             630.69,506.03 614.12,508.71 597.00,510.87
             591.45,511.58 593.54,509.35 581.00,511.61
             581.00,511.61 536.00,522.77 536.00,522.77
             536.00,522.77 506.00,528.28 506.00,528.28
             506.00,528.28 488.00,535.44 488.00,535.44
             488.00,535.44 481.00,536.61 481.00,536.61
             481.00,536.61 449.00,545.85 449.00,545.85
             449.00,545.85 417.00,553.23 417.00,553.23
             417.00,553.23 391.00,560.25 391.00,560.25
             391.00,560.25 358.00,568.00 358.00,568.00 Z"
      />
    </svg>
  );
};

export default DeepCrease;
