const Crease = ({
  className,
  showText,
  strokeWidth,
  filter,
  fill,
  stroke,
  transform,
  viewBox,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      stroke={stroke}
      preserveAspectRatio="xMidYMid meet"
      viewBox={viewBox}
      fill={fill}
      stroke-width={strokeWidth}
      filter={filter}
      class={className}
    >
      {showText && (
        <text
          fill="#fff"
          font-weight="bold"
          font-size="27"
          x="500"
          y="400"
          transform="scale(2.8)"
          paint-order="stroke"
          stroke="#000000"
          stroke-width="2px"
          stroke-linecap="butt"
          stroke-linejoin="miter"
        >
          Crease
        </text>
      )}

      <path
        transform={transform}
        id="Selection"
        d="M 306.00,622.00
          C 312.45,610.81 308.75,611.82 310.44,601.00
            311.56,593.83 315.28,580.40 314.91,574.00
            314.91,574.00 313.87,564.00 313.87,564.00
            313.87,564.00 313.87,551.00 313.87,551.00
            313.87,551.00 313.87,542.00 313.87,542.00
            314.01,533.82 316.27,525.90 318.27,518.00
            318.27,518.00 322.69,502.00 322.69,502.00
            322.69,502.00 328.74,489.00 328.74,489.00
            336.15,473.70 340.84,465.24 350.33,451.00
            358.75,438.37 372.52,416.59 382.75,406.21
            382.75,406.21 399.00,394.58 399.00,394.58
            411.53,385.63 434.62,371.92 449.00,367.00
            449.00,367.00 486.00,358.11 486.00,358.11
            514.24,351.62 541.07,346.90 570.00,344.91
            570.00,344.91 580.00,344.00 580.00,344.00
            580.00,344.00 601.00,343.00 601.00,343.00
            601.00,343.00 638.00,343.00 638.00,343.00
            638.00,343.00 655.00,344.00 655.00,344.00
            690.39,344.06 721.50,348.76 755.00,360.69
            776.85,368.47 796.28,380.50 816.00,392.40
            836.52,404.78 862.14,423.10 880.00,438.95
            884.41,442.87 898.71,454.95 901.15,459.00
            903.58,463.04 904.94,476.04 905.00,481.00
            905.00,481.00 905.00,488.00 905.00,488.00
            904.98,502.38 897.94,512.68 889.86,524.00
            881.79,535.30 868.83,551.38 859.00,560.99
            859.00,560.99 846.19,572.09 846.19,572.09
            846.19,572.09 828.00,599.00 828.00,599.00
            828.00,599.00 823.77,581.00 823.77,581.00
            823.77,581.00 818.31,562.00 818.31,562.00
            810.93,540.85 799.71,522.71 785.96,505.09
            782.73,500.95 777.30,497.66 773.00,494.58
            773.00,494.58 755.00,480.54 755.00,480.54
            733.04,462.69 731.75,460.40 706.00,448.06
            696.46,443.49 687.74,439.02 677.00,439.00
            677.00,439.00 653.00,439.00 653.00,439.00
            653.00,439.00 641.00,439.96 641.00,439.96
            641.00,439.96 629.00,439.96 629.00,439.96
            607.38,440.03 580.49,448.82 560.00,455.65
            560.00,455.65 525.00,465.59 525.00,465.59
            525.00,465.59 509.00,473.82 509.00,473.82
            509.00,473.82 480.00,488.36 480.00,488.36
            480.00,488.36 462.00,496.12 462.00,496.12
            462.00,496.12 450.00,503.37 450.00,503.37
            442.63,507.31 437.75,509.30 430.00,512.19
            430.00,512.19 421.00,515.35 421.00,515.35
            421.00,515.35 401.00,527.83 401.00,527.83
            401.00,527.83 383.00,536.33 383.00,536.33
            372.74,542.80 364.14,555.31 357.11,565.00
            357.11,565.00 338.10,590.91 338.10,590.91
            338.10,590.91 330.46,599.01 330.46,599.01
            330.46,599.01 323.55,607.91 323.55,607.91
            320.25,611.71 310.45,620.06 306.00,622.00 Z"
      />
    </svg>
  );
};

export default Crease;
