const OuterLid = ({
  className,
  showText,
  strokeWidth,
  filter,
  fill,
  stroke,
  transform,
  viewBox,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      stroke={stroke}
      preserveAspectRatio="xMidYMid meet"
      viewBox={viewBox}
      fill={fill}
      stroke-width={strokeWidth}
      filter={filter}
      class={className}
    >
      {showText && (
        <text
          font-weight="bold"
          font-size="27"
          x="610"
          y="600"
          transform="scale(2.8)"
          paint-order="stroke"
          stroke="#000000"
          stroke-width="2px"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          fill="white"
        >
          Outer Lid
        </text>
      )}
      <path
        id="Selection"
        transform={transform}
        d="M 595.00,511.00
           C 611.95,507.96 629.80,506.03 647.00,506.00
             647.00,506.00 662.00,505.00 662.00,505.00
             671.41,504.89 678.91,505.75 688.00,508.44
             709.17,514.70 731.99,534.88 749.00,548.69
             749.00,548.69 779.00,570.72 779.00,570.72
             779.00,570.72 818.00,598.95 818.00,598.95
             820.73,601.33 829.57,606.56 827.83,610.67
             826.61,613.56 817.06,617.53 814.00,618.80
             814.00,618.80 800.00,625.55 800.00,625.55
             800.00,625.55 777.00,633.75 777.00,633.75
             777.00,633.75 750.00,648.12 750.00,648.12
             750.00,648.12 735.00,658.68 735.00,658.68
             719.64,668.45 692.65,682.91 676.00,690.54
             676.00,690.54 651.00,703.26 651.00,703.26
             648.44,704.58 642.83,708.26 640.13,707.87
             637.68,707.52 636.66,705.04 634.75,703.59
             633.07,702.32 630.90,701.85 629.00,701.00
             632.56,697.94 628.76,692.49 627.08,689.00
             622.60,679.71 619.20,665.32 616.02,655.00
             616.02,655.00 606.49,623.00 606.49,623.00
             606.49,623.00 602.78,613.00 602.78,613.00
             601.53,610.29 600.50,609.06 599.53,606.00
             599.53,606.00 594.29,585.00 594.29,585.00
             594.29,585.00 593.71,575.00 593.71,575.00
             592.72,567.38 591.90,567.84 592.00,559.00
             592.00,559.00 592.91,549.00 592.91,549.00
             592.91,549.00 594.17,531.00 594.17,531.00
             594.66,526.54 596.14,522.52 595.82,518.00
             595.82,518.00 595.00,511.00 595.00,511.00 Z"
      />
    </svg>
  );
};

export default OuterLid;
