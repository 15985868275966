const InnerLid = ({
  className,
  showText,
  strokeWidth,
  filter,
  fill,
  stroke,
  transform,
  viewBox,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      stroke={stroke}
      preserveAspectRatio="xMidYMid meet"
      viewBox={viewBox}
      fill={fill}
      stroke-width={strokeWidth}
      filter={filter}
      class={className}
    >
      {showText && (
        <text
          font-weight="bold"
          font-size="27"
          x="300"
          y="640"
          transform="scale(2.8)"
          paint-order="stroke"
          stroke="#000000"
          stroke-width="2px"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          fill="white"
        >
          Inner Lid
        </text>
      )}
      <path
        id="Selection"
        transform={transform}
        d="M 425.00,739.00
           C 425.00,739.00 392.00,731.42 392.00,731.42
             392.00,731.42 341.00,716.28 341.00,716.28
             326.10,711.81 315.98,708.02 300.00,708.00
             300.00,708.00 275.09,709.23 275.09,709.23
             271.66,709.58 262.74,711.06 260.00,710.39
             253.28,708.76 250.66,702.31 251.10,696.00
             251.94,683.58 260.39,674.96 267.63,666.00
             267.63,666.00 297.75,630.87 297.75,630.87
             297.75,630.87 313.28,616.23 313.28,616.23
             313.28,616.23 321.08,609.68 321.08,609.68
             321.08,609.68 330.46,598.04 330.46,598.04
             330.46,598.04 336.54,591.83 336.54,591.83
             341.33,586.51 349.07,573.18 354.04,569.81
             360.14,565.69 381.57,562.46 390.00,560.25
             390.00,560.25 415.00,553.47 415.00,553.47
             415.00,553.47 447.00,546.11 447.00,546.11
             447.00,546.11 480.00,537.00 480.00,537.00
             472.02,550.22 460.31,582.78 457.61,598.00
             457.61,598.00 453.54,631.00 453.54,631.00
             453.54,631.00 446.53,659.00 446.53,659.00
             443.32,671.79 439.92,675.36 436.05,686.00
             436.05,686.00 428.32,714.00 428.32,714.00
             428.32,714.00 427.68,721.00 427.68,721.00
             426.35,729.11 425.03,729.48 425.00,739.00 Z"
      />
    </svg>
  );
};
export default InnerLid;
